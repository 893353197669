import React, { useState } from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import { commonService } from "../../services/common.service";
import ExportLoader from "../loader/ExportLoader";
import { ApiRoutes } from "../../constants";
import DownloadExcelPdfModal from "../../pages/Report/Modals/DownloadExcelPdfModal";
const XLSX = require("xlsx");
const APIBasePath = process.env.REACT_APP_API_URL;

export default function Export({
  data,
  loaderFlag,
  passFlag,
  key_headers,
  name = null,
  requestParams,
  newFlage,
}) {
  const [DownloadExcelPdfModalShow, setDownloadExcelPdfModalShow] =
    useState(false);
  const [ExcelPdfModalDetails, setExcelPdfModalDetails] = useState({
    pagename: "",
    endpoint: "",
    title: "",
    type: "",
    requestparams: "",
  });
  function convertArrayOfObjectsToCSV(array, key_header) {
    let result;
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.values(key_header);
    let headers = [];
    keys.forEach((header) => {
      headers = [...headers, header.text];
    });
    result = "";
    result += headers.join(columnDelimiter);
    result += lineDelimiter;
    array.forEach((item) => {
      let ctr = 0;
      Object.keys(key_header).forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
        if (item[key]) {
          if (key_headers[key].is_date) {
            if ("is_time" in item) {
              result += commonService.convertUtcTimeToLocalTime(
                item[key],
                key_headers[key].time_format,
                key_headers[key].date_format
              );
            } else {
              result += commonService.convertUTCToLocal(
                item[key],
                key_headers[key].date_format
              );
            }
          } else {
            result +=
              key === "is_leave" || key === "is_proxy"
                ? parseInt(item[key]) === 1
                  ? "Yes"
                  : ""
                : item[key];
            // result += item[key];
          }
        } else {
          result += "";
        }
        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  }

  function downloadCSV(array, key_header, filename = "export") {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array, key_header);
    if (csv == null) return;
    let filewithext = "";
    if (newFlage === "dailyNewpatrolling") {
      filewithext = "Daily Patrolling" + ".csv";
    } else {
      filewithext = filename + ".csv";
    }

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filewithext);
    link.click();
  }
  function downloadExcel(array, key_header, filename = "export") {
    let newArrayToExport = [];
    array.forEach((item) => {
      let newJson = {};
      Object.keys(key_header).forEach((key) => {
        let value = "";
        if (key_header[key].is_date) {
          value = commonService.convertUTCToLocal(
            item[key],
            key_header[key].date_format
          );
        } else {
          value =
            key === "is_leave" || key === "is_proxy"
              ? parseInt(item[key]) === 1
                ? "Yes"
                : ""
              : item[key];
        }
        newJson = {
          ...newJson,
          [key_header[key].text]: value,
        };
      });

      newArrayToExport = [...newArrayToExport, newJson];
    });

    let binaryWS = XLSX.utils.json_to_sheet(newArrayToExport);

    var wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, binaryWS, filename);

    XLSX.writeFile(wb, filename + ".xlsx");
  }

  const downloadPDF = (array, key_header, filename = "export") => {
    let data = [];
    array.forEach((item) => {
      // console.log(item)

      let arrayToAppend = [];
      Object.keys(key_header).forEach((key) => {
        let value = "";

        if (key_header[key].is_date) {
          value = commonService.convertUTCToLocal(
            item[key],
            key_header[key].date_format
          );
        } else {
          value =
            key === "is_leave" || key === "is_proxy"
              ? parseInt(item[key]) === 1
                ? "Yes"
                : ""
              : item[key];
        }
        arrayToAppend = [...arrayToAppend, value];
      });
      data = [...data, arrayToAppend];
    });
    const doc = new jsPDF();
    let headers = [];
    Object.values(key_header).forEach((header) => {
      headers = [...headers, header.text];
    });
    autoTable(doc, { html: "#my-table" });
    autoTable(doc, {
      head: [headers],
      body: data,
      headStyles: {
        textColor: "#000",
        halign: "center",
        valign: "middle",
        lineWidth: 0.1,
        lineColor: "#ABABAB",
        fillColor: "#fff",
        font: "times",
        fontStyle: "bold",
      },
      styles: {
        textColor: "#000",
        halign: "center",
        valign: "middle",
        font: "times",
      },
      bodyStyles: {
        textColor: "#000",
        halign: "center",
        valign: "middle",
        font: "times",
      },
      margin: { top: 0, bottom: 0, left: 5, right: 5 },
      theme: "grid",
    });
    doc.save(filename + ".pdf");
  };

  const demopdf = (requestParams, newFlage) => {
    var endpoint = "";

    if (newFlage === "dailyNewpatrolling") {
      // endpoint = ApiRoutes.generateDailyPatrollingPdf;
      setDownloadExcelPdfModalShow(true);
      setExcelPdfModalDetails({
        ...ExcelPdfModalDetails,
        pagename: newFlage,
        endpoint: ApiRoutes.generateDailyPatrollingPdf,
        title: "PDF",
        type: "pdf",
        requestparams: requestParams,
      });
      return;
    } else if (newFlage === "newAttendanceReport") {
      loaderFlag(true);
      endpoint = ApiRoutes.generateGuardAttendancePdf;
    } else if (newFlage === "incidentReport") {
      loaderFlag(true);
      endpoint = ApiRoutes.generateIncidentReportPdf;
    }

    requestParams.downloadFileType = "pdf";

    // Prepare request metadata
    const requestMetadata = {
      method: "POST",
      headers: {
        ...commonService.getHeaders(),
        "Content-Type": "application/json", // Assuming JSON is expected by the API
      },
      body: JSON.stringify(requestParams), // Convert requestParams to JSON string
    };

    // Make the POST request to the API
    fetch(endpoint, requestMetadata)
      .then((response) => {
        loaderFlag(false);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        var filename = "Report.pdf";
        if (newFlage === "dailyNewpatrolling") {
          filename = "Daily Patrolling.pdf";
        } else if (newFlage === "newAttendanceReport") {
          filename = "Attendance List.pdf";
        } else if (newFlage === "incidentReport") {
          filename = "Incident List.pdf";
        }

        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const demoexcel = (requestParams, newFlage) => {
    // Define the endpoint of API
    // const endpoint = APIBasePath + "contract/neel_demo_pdf";
    var endpoint = "";

    if (newFlage === "dailyNewpatrolling") {
      // endpoint = ApiRoutes.generateDailyPatrollingPdf;
      setDownloadExcelPdfModalShow(true);
      setExcelPdfModalDetails({
        ...ExcelPdfModalDetails,
        pagename: newFlage,
        endpoint: ApiRoutes.generateDailyPatrollingPdf,
        title: "EXCEL",
        type: "excel",
        requestparams: requestParams,
      });
    } else if (newFlage === "newAttendanceReport") {
      loaderFlag(true);
      endpoint = ApiRoutes.generateGuardAttendancePdf;

      requestParams.downloadFileType = "excel";

      // Prepare request metadata
      const requestMetadata = {
        method: "POST",
        headers: {
          ...commonService.getHeaders(),
          "Content-Type": "application/json", // Assuming JSON is expected by the API
        },
        body: JSON.stringify(requestParams), // Convert requestParams to JSON string
      };

      // console.log(requestMetadata);

      // Make the POST request to the API
      fetch(endpoint, requestMetadata)
        .then((response) => {
          loaderFlag(false);
          // Check if the response is ok (status code 200-299)
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          // Return the response as a blob (binary large object)
          return response.blob();
        })
        .then((blob) => {
          // Create a URL for the blob object
          const url = window.URL.createObjectURL(blob);
          // Create a link element
          const link = document.createElement("a");
          // Set the href attribute of the link to the blob URL
          link.href = url;
          // Set the download attribute to suggest a filename
          var filename = "Report.xlsx";

          if (newFlage === "dailyNewpatrolling") {
            filename = "Daily Patrolling.xlsx";
          } else if (newFlage === "newAttendanceReport") {
            filename = "Attendance List.xlsx";
          }

          link.setAttribute("download", filename);
          // Append the link to the document body
          document.body.appendChild(link);
          // Programmatically click the link to trigger the download
          link.click();
          // Remove the link from the document
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  };

  return (
    <Dropdown>
      {newFlage === "dailyNewpatrolling" ||
      newFlage === "newAttendanceReport" ||
      newFlage === "incidentReport" ? (
        <>
          {passFlag ? (
            <ExportLoader />
          ) : (
            <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
              <i className="fa fa-download" aria-hidden="true"></i>
            </Dropdown.Toggle>
          )}
        </>
      ) : (
        <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
          <i className="fa fa-download" aria-hidden="true"></i>
        </Dropdown.Toggle>
      )}
      <Dropdown.Menu>
        {name !== "incident" && (
          <>
            <Dropdown.Item
              onClick={(e) => {
                downloadCSV(data, key_headers, name);
              }}
            >
              <i className="fa-solid fa-file-csv"></i> CSV
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                if (
                  newFlage === "dailyNewpatrolling" ||
                  newFlage === "newAttendanceReport"
                ) {
                  demoexcel(requestParams, newFlage); // Neel code
                } else {
                  downloadExcel(data, key_headers, name);
                }
              }}
            >
              <i className="fa fa-file-excel" aria-hidden="true"></i> EXCEL
            </Dropdown.Item>{" "}
          </>
        )}

        <Dropdown.Item
          onClick={(e) => {
            if (
              newFlage === "dailyNewpatrolling" ||
              newFlage === "incidentReport"
            ) {
              demopdf(requestParams, newFlage); // Neel code
            } else {
              downloadPDF(data, key_headers, name);
            }
          }}
        >
          <i className="fa fa-file-pdf"></i> PDF
        </Dropdown.Item>
      </Dropdown.Menu>
      {DownloadExcelPdfModalShow && (
        <DownloadExcelPdfModal
          show={DownloadExcelPdfModalShow}
          detail={ExcelPdfModalDetails}
          onHide={() => setDownloadExcelPdfModalShow(false)}
        />
      )}
    </Dropdown>
  );
}
