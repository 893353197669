import React, { useState } from "react";
import { Button, Form, Modal, Row, Spinner } from "react-bootstrap";
import InputText from "../../../components/form/inputText";
import { commonService } from "../../../services/common.service";
import { ApiRoutes } from "../../../constants";

const DownloadExcelPdfModal = (props) => {
  const [Loading, setLoading] = useState(false);

  const [Input, setInput] = useState({
    email: "",
    errors: {
      email: "",
      ValidationRules: [
        {
          FieldName: "email",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "email",
          ValidationType: "email",
          ValidationMessage: "Invalid email format",
        },
      ],
    },
  });  
  const NewapiforStatus = () => {
    fetch(ApiRoutes.contractcheckExportStatus, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + commonService.getItem("auth_token"),
      },
      body: JSON.stringify({
        email: Input?.email?.trim(),
        downloadFileType: props.detail.type,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status === 1) {
          setLoading(false);
          props.onHide();

        }
        // Start checking if job is done
        // checkJobCompletion();
      });
    const checkJobCompletion = () => {
      const interval = setInterval(() => {
        fetch(
          `${ApiRoutes.contractcheckExportStatus
          }?email=${Input?.email?.trim()}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + commonService.getItem("auth_token"),
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 1) {
              clearInterval(interval);
              setLoading(false);
              props.onHide(); // Close modal after job completion
            }
          });
      }, 5000); // Check every 5 seconds
    };
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = commonService.fnCheckValidationOfObject(Input);
    setInput({
      ...obj.obj,
    });
    if (obj.isValid) {
      setLoading(true);
      props.detail.requestparams.email = Input?.email?.trim();
      if (props.detail.type === "excel") {
        props.detail.requestparams.downloadFileType = "excel";
        // Prepare request metadata
        const requestMetadata = {
          method: "POST",
          headers: {
            ...commonService.getHeaders(),
            "Content-Type": "application/json", // Assuming JSON is expected by the API
          },
          body: JSON.stringify(props.detail.requestparams), // Convert requestParams to JSON string
        };
        // Make the POST request to the API
        fetch(props?.detail?.endpoint, requestMetadata)
          .then((response) => {
            if (response.ok) {
              NewapiforStatus();
            }
          })
          .catch((error) => {
            console.error(
              "There was a problem with the fetch operation:",
              error
            );
          });
      } else if (props.detail.type === "pdf") {
        props.detail.requestparams.downloadFileType = "pdf";
        // Prepare request metadata
        const requestMetadata = {
          method: "POST",
          headers: {
            ...commonService.getHeaders(),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(props.detail.requestparams),
        };
        fetch(props.detail.endpoint, requestMetadata)
          .then((response) => {
            if (response.ok) {
              NewapiforStatus();
            }
          })
          .catch((error) => {
            console.error(
              "There was a problem with the fetch operation:",
              error
            );
          });
      }
      // fetch(ApiRoutes.contractcheckExportStatus, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer " + commonService.getItem("auth_token"),
      //   },
      //   body: JSON.stringify({
      //     email: Input?.email?.trim(),
      //     downloadFileType: props.detail.type,
      //   }),
      // })
      //   .then((response) => response.json())
      //   .then(() => {
      //     // Start checking if job is done
      //     checkJobCompletion();
      //   });
      // const checkJobCompletion = () => {
      //   const interval = setInterval(() => {
      //     fetch(
      //       `${
      //         ApiRoutes.contractcheckExportStatus
      //       }?email=${Input?.email?.trim()}`,
      //       {
      //         method: "POST",
      //         headers: {
      //           "Content-Type": "application/json",
      //           Authorization: "Bearer " + commonService.getItem("auth_token"),
      //         },
      //       }
      //     )
      //       .then((response) => response.json())
      //       .then((data) => {
      //         if (data.status === 1) {
      //           clearInterval(interval);
      //           setLoading(false);
      //           alert("Export completed and sent via email!");
      //           props.onHide(); // Close modal after job completion
      //         }
      //       });
      //   }, 5000); // Check every 5 seconds
      // };
      // props.detail.requestparams.email = Input?.email?.trim();
      // if (props.detail.type === "excel") {
      //   debugger;
      //   fetch(ApiRoutes.contractcheckExportStatus, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: "Bearer " + commonService.getItem("auth_token"),
      //     },
      //     body: JSON.stringify({
      //       email: Input?.email?.trim(),
      //       downloadFileType: "excel",
      //     }),
      //   })
      //     .then((response) => response.json())
      //     .then(() => {
      //       // Start checking if job is done
      //       checkJobCompletion();
      //     });
      //   const checkJobCompletion = () => {
      //     const interval = setInterval(() => {
      //       fetch(
      //         `${
      //           ApiRoutes.contractcheckExportStatus
      //         }?email=${Input?.email?.trim()}`,
      //         {
      //           method: "POST",
      //           headers: {
      //             "Content-Type": "application/json",
      //             Authorization:
      //               "Bearer " + commonService.getItem("auth_token"),
      //           },
      //         }
      //       )
      //         .then((response) => response.json())
      //         .then((data) => {
      //           if (data.status === 1) {
      //             clearInterval(interval);
      //             setLoading(false);
      //             alert("Export completed and sent via email!");
      //             props.onHide(); // Close modal after job completion
      //           }
      //         });
      //     }, 5000); // Check every 5 seconds
      //   };
      // } else if (props.detail.type === "pdf") {
      //   props.detail.requestparams.downloadFileType = "pdf";

      //   // Prepare request metadata
      //   const requestMetadata = {
      //     method: "POST",
      //     headers: {
      //       ...commonService.getHeaders(),
      //       "Content-Type": "application/json", // Assuming JSON is expected by the API
      //     },
      //     body: JSON.stringify(props.detail.requestparams), // Convert requestParams to JSON string
      //   };

      //   // Make the POST request to the API
      //   fetch(props.detail.endpoint, requestMetadata)
      //     .then((response) => {
      //       setLoading(false);
      //       // Check if the response is ok (status code 200-299)
      //       if (!response.ok) {
      //         throw new Error("Network response was not ok");
      //       }
      //       // Return the response as a blob (binary large object)
      //       // return response.blob();
      //     })
      //     .then((blob) => {})
      //     .catch((error) => {
      //       console.error(
      //         "There was a problem with the fetch operation:",
      //         error
      //       );
      //     });
      // }
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.detail?.title}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <InputText
            size={12}
            type={"email"}
            title={"Email"}
            value={Input.email}
            onChange={(value) =>
              setInput({
                ...Input,
                email: value,
              })
            }
            validationText={Input.errors.email}
          // required
          />
        </Modal.Body>
        <Modal.Footer>
          {Loading ? (
            <Button variant="primary">
              <Spinner animation="border" size="sm" /> Loading...
            </Button>
          ) : (
            <Button variant="primary" type="submit">
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DownloadExcelPdfModal;
